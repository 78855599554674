/*
 * `ng build --configuration=dev` in `CM frontend` pipeline npm `build DEV` step
 * not currently using the `dev` config within angular.json as expected.
 * Until further notice, assume DEV and QA deployments will use shared environment.ts (default, non-custom non-prod config)
 */

export const environment = {
	production: false,
	envName: 'dev',
	routeGuardsEnabled: false,
	reportsEnabled: false,
	sessionTimeOut: 20,
	apiUrl: 'https://wap-mistars-api-dev-eastus2.azurewebsites.net/v1/',

	msalConfig: {
		auth: {
			clientId: 'd9b77d54-ad47-4af4-b45f-cbf74a7ca713',
			authority:
				'https://login.microsoftonline.com/e7520e4d-d5a0-488d-9e9f-949faae7dce8',
		},
	},
	apiConfig: {
		scopes: ['api://d9b77d54-ad47-4af4-b45f-cbf74a7ca713/Read.MIStarsDev'],
		uri: 'https://wap-mistars-api-dev-eastus2.azurewebsites.net/v1/',
	},
	reportsConfig: [],
};
